
import { observableFromEvent } from 'vs/base/common/observable';
import { ICodeEditor } from 'vs/editor/browser/editorBrowser';

/**
 * Returns a facade for the code editor that provides observables for various states/events.
*/
export function obsCodeEditor(editor: ICodeEditor): ObservableCodeEditor {
	return ObservableCodeEditor.get(editor);
}

class ObservableCodeEditor {
	private static _map = new Map<ICodeEditor, ObservableCodeEditor>();

	/**
	 * Make sure that editor is not disposed yet!
	*/
	public static get(editor: ICodeEditor): ObservableCodeEditor {
		let result = ObservableCodeEditor._map.get(editor);
		if (!result) {
			result = new ObservableCodeEditor(editor);
			ObservableCodeEditor._map.set(editor, result);
			const d = editor.onDidDispose(() => {
				ObservableCodeEditor._map.delete(editor);
				d.dispose();
			});
		}
		return result;
	}

	private constructor(public readonly editor: ICodeEditor) {
	}

	public readonly model = observableFromEvent(this.editor.onDidChangeModel, () => this.editor.getModel());
}
